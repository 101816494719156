// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import MainServices from 'components/promptService/mainServices';
import SearchDoctor from 'components/SearchDoctor';
import { InformationCardDownload } from 'components/information-card/InformationCard';
import Accordion from 'components/Accordion';

import { HeaderContext } from 'context/header';

// Images
import BlobPurpleAndLilac from 'images/blobs/double_Islime.svg';
import ReactMarkdown from 'react-markdown';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '0 20px'};

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const GridMainServices = styled.div`
  margin: ${props => (props.marginDesktop ? props.marginDesktop : '0')};
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;
  background: #f4f5f7;

  @media ${device.laptop} {
    display: flex;
    flex-direction: column-reverse;
    padding: 0 20px;
    margin: 0 -20px 1.25rem;
  }
`;

const Part = styled.div`
  position: relative;
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding: ${props =>
    props.paddingDesktop ? props.paddingDesktop : '4.375rem 0'};
  margin: ${props => (props.marginDesktop ? props.marginDesktop : '0')};

  @media ${device.laptop} {
    flex-direction: column;
    padding: ${props =>
      props.paddingLaptop ? props.paddingLaptop : '1.25rem 0'};
    margin: ${props => (props.marginLaptop ? props.marginLaptop : '0')};
  }
`;

const ImageBlobPurpleAndLilac = styled.div`
  position: absolute;
  width: 100%;
  max-width: 36.5rem;
  height: 10rem;

  background-image: url(${BlobPurpleAndLilac});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  bottom: 0;
  left: -0.75rem;
  z-index: -1;

  @media ${device.laptop} {
    display: none;
  }
`;

const PartTitle = styled(ReactMarkdown)`
  h1,
  h2,
  h3,
  h4 {
    font-size: 2.25rem;
    line-height: 2.875rem;
    color: #231f20;
    font-weight: 600;
    margin: 0;
    text-align: center;

    @media ${device.laptop} {
      font-size: 1.125rem;
      line-height: 1.875rem;
    }
  }
`;

const SearchDoctorContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 4.375rem;
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = findFragment;

    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return [findFragment];
  }
};

const RenderPage = (page, location, isDesktop) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / -2'} borderBottom>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwxuncb4621a0b76q6x0bjny', true)}
            fragments={getMarkdown(page, 'ckwxuncb4621a0b76q6x0bjny')}
            padding="0"
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            withoutBorderTop
            boxContentPaddingBottomLaptop="0"
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom>
          <ImageBlobPurpleAndLilac />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwxuus205zh60b73uxqvaa17', true)}
            fragments={getMarkdown(page, 'ckwxuus205zh60b73uxqvaa17')}
            padding="0"
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            contentTagImgTransform="unset"
            withoutBorderTop
            contentImgWidthLaptop="unset"
            noPaddingTop
            contactFlex="flex"
            paddingBottom
            tittleMarginBottom
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwxuyuns60c30b73k0584b8s', true)}
            fragments={getMarkdown(page, 'ckwxuyuns60c30b73k0584b8s')}
            isDropdownDesktop
            prontoAtendimento
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="0"
            boxContentPaddingBottomLaptop="0"
            contentFontSizeMob="0.875rem"
            displayTitle="none"
            cuidados
            dropdownStyle
            contentTagEmColor="#231F20"
            contentContactCardChild3
            contentContactCardChild3Margin="1.25rem 0 0"
            contentContactCardChild4
            contentContactCardChild4Margin="1.25rem 0 0"
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwxv0frc61j10c66g9y1gmhu', true)}
            fragments={getMarkdown(page, 'ckwxv0frc61j10c66g9y1gmhu')}
            isDropdownDesktop
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="1.25rem 0 0"
            boxContentPaddingBottomLaptop="0"
            contentFontSizeMob="0.875rem"
            displayTitle="none"
            cuidados
            dropdownStyle
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwxvtz146ofa0b73qmsh5sb1', true)}
            fragments={getMarkdown(page, 'ckwxvtz146ofa0b73qmsh5sb1')}
            isDropdownDesktop
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="1.25rem 0 0"
            boxContentPaddingBottomLaptop="0"
            contentFontSizeMob="0.875rem"
            displayTitle="none"
            cuidados
            dropdownStyle
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwxvvn7s6yu20d681cx01ssv', true)}
            fragments={getMarkdown(page, 'ckwxvvn7s6yu20d681cx01ssv')}
            isDropdownDesktop
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="1.25rem 0 0"
            boxContentPaddingBottomLaptop="0"
            contentFontSizeMob="0.875rem"
            displayTitle="none"
            cuidados
            dropdownStyle
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwxvwo946qz00c66oisbct5a', true)}
            fragments={getMarkdown(page, 'ckwxvwo946qz00c66oisbct5a')}
            isDropdownDesktop
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="1.25rem 0 0"
            boxContentPaddingBottomLaptop="0"
            contentFontSizeMob="0.875rem"
            displayTitle="none"
            cuidados
            dropdownStyle
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwxvxm7c6u0d0c66flzoyjox', true)}
            fragments={getMarkdown(page, 'ckwxvxm7c6u0d0c66flzoyjox')}
            isDropdownDesktop
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="1.25rem 0 0"
            boxContentPaddingBottomLaptop="0"
            contentFontSizeMob="0.875rem"
            displayTitle="none"
            cuidados
            dropdownStyle
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwxvz82o76z50b763878iix7', true)}
            fragments={getMarkdown(page, 'ckwxvz82o76z50b763878iix7')}
            isDropdownDesktop
            containerPaddingDesk="1.875rem 0 0"
            containerPaddingLaptop="1.25rem 0 0"
            boxContentPaddingBottomLaptop="0"
            contentFontSizeMob="0.875rem"
            displayTitle="none"
            cuidados
            dropdownStyle
          />
        </Part>
        <Part gridColumn={'2 / -2'} borderBottom>
          <PartTitle>
            {getMarkdown(page, 'ckwxwfn4g7gsd0b76rit6gep7', true)}
          </PartTitle>
        </Part>
        <Part gridColumn={'2 / -2'}>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwzg79tcncpd0c66ie1rhc8r', true)}
            fragments={getMarkdown(page, 'ckwzg79tcncpd0c66ie1rhc8r')}
            padding="0"
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            withoutBorderTop
            boxContentPaddingBottomLaptop="0"
          />
          <InformationCardDownload
            markdown={getMarkdown(page, 'ckwxwoy007hcs0c668au28nep', false)}
            isContainerMarginBottomInformativeMaterial="1.875rem"
            containerInformationWidthDesktop="35.625rem"
            marginRightCard="1.875rem"
            containerTitleWidthDesktop="37.5rem"
            titleMarginDesktop="0"
            containerCardFlexDirectionLaptop="column"
            containerInformationWidthLaptop="100%"
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckwxwh4d478800b73bhz40ju0', true)}
            fragments={getMarkdown(page, 'ckwxwh4d478800b73bhz40ju0')}
            padding="0"
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            withoutBorderTop
            boxContentPaddingBottomLaptop="0"
            contentContactCardChild2
            contentContactCardChild3
            contentTagEmColor="#231F20"
          />
        </Part>
        <Part gridColumn={'2 / -2'} paddingDesktop="0" paddingLaptop="0">
          <Accordion
            markdownContent={getMarkdown(
              page,
              'ckwxx44ao87080b76pjcqn34u',
              true
            )}
          >
            <PromptServiceContent
              markdown={getMarkdown(page, 'ckwxx44ao87080b76pjcqn34u', true)}
              fragments={getMarkdown(page, 'ckwxx44ao87080b76pjcqn34u')}
              containerPaddingDesk="0"
              notTitleBold
              facilitiesForYou
              isBottom
              prontoAtendimento
              isFacilities
              contentTagImgTransform="unset"
              titleWidth
              withoutBorderTop
              hasIframe
              contentImgWidthLaptop="100%"
              notDuplicateUl="none"
              notSpanTitle
              isAccordion
              isJustCards
              isCDICardio
              contentTagUlListStyleType="none"
              isMarginLeftPromptService
              contentLiMarginBottom="unset"
              listBullets
              contentPOrder="unset"
              containerPLastChildMarginTop="0"
            />
          </Accordion>
          <Accordion
            markdownContent={getMarkdown(
              page,
              'ckx0emgxcubk40b76mxj5apa7',
              true
            )}
          >
            <PromptServiceContent
              markdown={getMarkdown(page, 'ckx0emgxcubk40b76mxj5apa7', true)}
              fragments={getMarkdown(page, 'ckx0emgxcubk40b76mxj5apa7')}
              containerPaddingDesk="0"
              notTitleBold
              facilitiesForYou
              isBottom
              prontoAtendimento
              isFacilities
              contentTagImgTransform="unset"
              titleWidth
              withoutBorderTop
              hasIframe
              contentImgWidthLaptop="100%"
              notDuplicateUl="none"
              notSpanTitle
              isAccordion
              isJustCards
              isCDICardio
              contentTagUlListStyleType="none"
              isMarginLeftPromptService
              contentLiMarginBottom="unset"
              listBullets
              contentPOrder="unset"
              containerPLastChildMarginTop="0"
            />
          </Accordion>
          <Accordion
            markdownContent={getMarkdown(
              page,
              'ckwxx87o87zmh0b73l992jkfc',
              true
            )}
          >
            <PromptServiceContent
              markdown={getMarkdown(page, 'ckwxx87o87zmh0b73l992jkfc', true)}
              fragments={getMarkdown(page, 'ckwxx87o87zmh0b73l992jkfc')}
              containerPaddingDesk="0"
              notTitleBold
              facilitiesForYou
              isBottom
              prontoAtendimento
              isFacilities
              contentTagImgTransform="unset"
              titleWidth
              withoutBorderTop
              hasIframe
              contentImgWidthLaptop="100%"
              notDuplicateUl="none"
              notSpanTitle
              isAccordion
              isJustCards
              isCDICardio
              contentTagUlListStyleType="none"
              isMarginLeftPromptService
              contentLiMarginBottom="unset"
              listBullets
              contentPOrder="unset"
              containerPLastChildMarginTop="0"
            />
          </Accordion>
          <Accordion
            markdownContent={getMarkdown(
              page,
              'ckwxxatt484kx0b730ctjg0yi',
              true
            )}
          >
            <PromptServiceContent
              markdown={getMarkdown(page, 'ckwxxatt484kx0b730ctjg0yi', true)}
              fragments={getMarkdown(page, 'ckwxxatt484kx0b730ctjg0yi')}
              containerPaddingDesk="0"
              notTitleBold
              facilitiesForYou
              isBottom
              prontoAtendimento
              isFacilities
              contentTagImgTransform="unset"
              titleWidth
              withoutBorderTop
              hasIframe
              contentImgWidthLaptop="100%"
              notDuplicateUl="none"
              notSpanTitle
              isAccordion
              isJustCards
              isCDICardio
              contentTagUlListStyleType="none"
              isMarginLeftPromptService
              contentLiMarginBottom="unset"
              listBullets
              contentPOrder="unset"
              containerPLastChildMarginTop="0"
            />
          </Accordion>
        </Part>
        <Part
          gridColumn={'2 / -2'}
          paddingDesktop="4.375rem 0 0"
          paddingLaptop="1.25rem 0 0"
        >
          <SearchDoctorContainer>
            <SearchDoctor
              location={location}
              externalPage
              textLabels={
                getMarkdown(page, 'ckx0m9b7sx9c00b76sntnijgz')?.[0]?.singletexts
              }
            />
          </SearchDoctorContainer>
        </Part>
        <GridMainServices
          marginDesktop="0 0 4.375rem"
          marginLaptop="0 0 1.25rem"
        >
          <Part gridColumn={'2 / -2'}>
            <MainServices
              markdown={getMarkdown(page, 'ckwxxe1js86300b73yz96m522', true)}
              isPatologica
              liMarginBottomDesk="2.25rem"
              liLastChildUnsetHeight
              gridTemplateColumnsDesktop="unset"
              containerUlGridColumnsLap="unset"
              paddingDesktop="0"
              styleInColumn
              containerWidthLaptop="unset"
              isCIGI
            ></MainServices>
          </Part>
        </GridMainServices>
      </GridContainer>
    </>
  );
};

const InternationalPatient = ({ page, location }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = page?.[0]?.featuredMarketing?.images?.[0];

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {RenderPage(page, location, isDesktop)}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckwxr23j43p610d68om5qhgzo" }) {
                featuredMarketing(locales: [en, es, pt_BR]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  activeHighlight
                  highlight
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                fragments(locales: [en, es, pt_BR]) {
                  id
                  name
                  singletexts
                  markdown
                  datafragment
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <InternationalPatient page={response.gcms.site.pages} {...props} />
        );
      }}
    />
  );
};
